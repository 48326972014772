<template>
  <div class="chat">
    <section class="tickets">
      <header>
        <span class="title">Messages</span>
      </header>
      <div class="select-type">
        <span :class="{ active: mode === 'single' }" @click="mode = 'single'"
          >Single</span
        >
        <span :class="{ active: mode === 'group' }" @click="mode = 'group'"
          >Group</span
        >
        <hr :class="{ right: mode === 'group' }" />
      </div>
      <div class="new-chat">
        <span @click="search = !search">
          <span v-if="!search">+ Start Conversation</span>
          <span v-else>Close</span>
        </span>
        <search-users v-if="search" @createChat="handleCreate" />
      </div>
      <div class="wrapper">
        <template v-if="loaded && items.length">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="ticket"
            :class="{ active: isActive === item._id || isActive === index }"
            @click="isActive = item._id"
          >
            <div class="info">
              <span class="user-img" v-if="item.imageBase64">
                <img
                  :src="'data:image/png;base64, ' + item.imageBase64"
                  alt="avatar"
                />
                <span class="online" v-if="otherUser(item)[0].isOnline"></span>
              </span>
              <span class="user-img" v-else-if="otherUser(item)[0].userInfo.avatar">
                <img
                  :src="imgURL + otherUser(item)[0].userInfo.avatar"
                  alt="avatar"
                />
                <span class="online" v-if="otherUser(item)[0].isOnline"></span>
              </span>
              <span class="user-img no-img" v-else>
                  <span class="user-char" :class="{ active: isActive === item._id || isActive === index }" v-if="item.name">
                        {{item.name.charAt(0)}}
                    </span>
                    <span class="user-char" :class="{ active: isActive === item._id || isActive === index }" v-else-if="otherUser(item)[0].userInfo.name">
                        {{otherUser(item)[0].userInfo.name.charAt(0)}}
                    </span>
                <span class="online" v-if="otherUser(item)[0].isOnline"></span>
              </span>
              <span class="user-name">{{
                item.name || otherUser(item)[0].userInfo.name
              }}</span>
              <span class="unread-chats" v-if="reads[item._id] > 0">{{
                reads[item._id]
              }}</span>
              <span
                class="unread-chats"
                v-else-if="reads[item._id] !== 0 && item.unreadChatCount"
                >{{ item.unreadChatCount }}</span
              >
            </div>
          </div>
        </template>
        <template v-else-if="loaded && items && !items.length">
          <div class="empty">
            <span class="img">
              <img src="/assets/images/chat_baloon.svg" alt="" />
            </span>
            <span>No chats were Initiated</span>
            <span class="new" @click="search = !search"
              >Start a Conversation</span
            >
          </div>
        </template>
        <template v-else>
          <div class="loading">
            <span class="loader"></span>
          </div>
        </template>
      </div>
    </section>
    <section class="messages" v-if="Object.keys(selectedChat).length">
      <container
        :chat_item="selectedChat"
        :mode="mode"
        :loaded="loaded"
        @reads="handleRead"
      />
    </section>
    <section class="empty-container" v-else>
      <span class="img">
        <img src="/assets/images/chat_baloon.svg" alt="" />
      </span>
      <span>No chats were Initiated</span>
    </section>
  </div>
</template>

<script>
import Container from "./Container.vue";
import SearchUsers from "./SearchUser.vue";
import { mapMutations } from "vuex";
export default {
  props: {
    items: {
      required: false,
      type: Array,
    },
    loaded: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: 0,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      selectedChat: this.items[0],
      mode: "single",
      search: false,
      groupImage: "",
      groupName: "",
      roomParticipants: [],
      organization_details: JSON.parse(localStorage.getItem("organization")),
      reads: {},
    };
  },
  components: {
    Container,
    SearchUsers,
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (!val.length) {
          this.setDisabled(true);
          this.selectedChat = {};
        } else if (this.$route.query.roomId) {
          this.isActive = this.$route.query.roomId;
          this.$router.replace({ query: {} });
        } else this.isActive = Object.keys(val).length ? val[0]._id : 0;
      },
    },
    isActive: {
      immediate: true,
      handler(val) {
        if (val)
          this.selectedChat = this.items.filter((el) => el._id === val)[0];
      },
    },

    mode(val) {
      this.isActive = 0
      this.$emit("mode", val);
    },
  },

  methods: {
    ...mapMutations({
      setDisabled: "call/setDisabled",
    }),
    otherUser(item) {
      return item.users.filter((el) => el.userId !== this.user.id);
    },
    async handleCreate(data) {
      this.search = false;

      if (Array.isArray(data)) {
        const existing = data[0].room ? data[0].room._id : "";
        const toCreate = this.items
          .map((el) => el._id)
          .filter((el) => el === existing);
        if (!toCreate.length) {
          this.roomParticipants.push(
            ...data.map((el) => {
              return el.userId;
            })
          );

          this.createRoom();
        }
      } else {
        this.roomParticipants.push(
          ...data.users.map((el) => {
            return el.userId;
          })
        );
        if (data.groupImage) this.groupImage = data.groupImage;
        if (data.groupName) this.groupName = data.groupName;

        this.createRoom();
      }
    },

    createRoom() {
      const params = {
        organizationId: this.organization_details.id,
        participants: this.roomParticipants,
        type: this.roomParticipants.length > 1 ? "group" : "single",
      };
      if (this.groupImage) {
        params.groupImage = this.groupImage;
      }
      if (this.groupName) {
        params.groupName = this.groupName;
      }
      this.emitter.emit("create-room", params);
      this.$emit("reloadChats");
    },

    handleRead(data) {
      if (data > 0) {
        const unreads = this.items.filter(
          (el) => el._id === this.selectedChat._id
        )[0].unreadChatCount;
        this.reads[this.selectedChat._id] = unreads - data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  display: flex;
  gap: 5px;
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }
  .tickets {
    background: white;
    width: 20%;
    height: 65vh;
    border-radius: 5px;
    box-shadow: 0px 1px 6px #cad6e280;
    @media only screen and (max-width: 1200px) {
        width: 100%;
        height: 10.5rem;
    }
    header {
      height: 12%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      box-shadow: 0px 1px 6px #cad6e280;
      border-bottom: solid 1px #f2f5f8;
      display: flex;
      flex-direction: column;
      gap: 2px;
        @media only screen and (max-width: 1200px) {
            display: none;
        }
      .title {
        font-size: 1.2rem;
        font-weight: 500;
      }
      .date {
        font-size: 0.8rem;
        color: #cad6e2;
      }
    }

    .select-type {
      display: flex;
      height: 8%;
      width: 100%;
      position: relative;

        @media only screen and (max-width: 1200px) {
            height: 3rem;
        }
      span {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-weight: 500;
        user-select: none;
        cursor: pointer;
        &.group {
          cursor: not-allowed;
        }
        &.active {
          color: var(--primary-color);
        }
      }
      hr {
        border: none;
        height: 3px;
        background: var(--primary-color);
        width: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: 0.2s;
        &.right {
          left: 50%;
        }
      }
    }

    .new-chat {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2px;
      height: 8%;
      position: relative;
        @media only screen and (max-width: 1200px) {
            padding: 20px 0;
        }
      span {
        padding: 0 20px;
        color: var(--primary-color);
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        span {
          padding: 0;
        }
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      height: 72%;
      overflow: auto;
        @media only screen and (max-width: 1200px) {
            flex-direction: row;
            height: fit-content;
        }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #f2f5f8;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        transition: 0.2s;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
      }

      .empty {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #cad6e2;

        .img {
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .new {
          color: var(--primary-color);
          cursor: pointer;
          user-select: none;
          font-weight: 500;
        }
      }
      .ticket {
        display: flex;
        padding: 20px;
        user-select: none;
        cursor: pointer;
        transition: 0.2s;
        position: relative;
        &.active {
          background: var(--primary-color);
          color: white;
          &:hover {
            background: var(--primary-color);
          }
          .info {
            .ticket-title {
              color: white;
            }
            .product-name {
              color: white;
            }

            span {
              .name {
                color: white;
              }
            }

            .unread-chats {
              background: white;
              color: var(--primary-color);
            }
          }
        }
        &:hover {
          background: #0072FFc7;
          color: white;
          .info {
            .ticket-title {
              color: white;
            }
            .product-name {
              color: white;
            }

            span {
              .name {
                color: white;
              }
            }
          }
        }
        .info {
          display: flex;
          align-items: center;
          gap: 10px;
          .user-img {
            width: 40px;
            height: 40px;
            position: relative;

            &.no-img {
              .user-char {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                background: var(--primary-color);
                color: white;
                border-radius: 50%;

                &.active {
                background: white;
                color: var(--primary-color);
                }
              }
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
            .online {
              position: absolute;
              width: 10px;
              height: 10px;
              background: green;
              bottom: 0;
              right: 3px;
              border: solid 1px white;
              border-radius: 50%;
            }
          }
          .user-name {
            width: calc(100% - 50px);
            font-weight: 500;
          }

          .unread-chats {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            background: var(--primary-color);
            font-size: 0.5rem;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .messages {
    background: white;
    width: 80%;
    height: 65vh;
    border-radius: 5px;
    box-shadow: 0px 1px 6px #cad6e280;
    position: relative;
    @media only screen and (max-width: 1200px) {
        width: 100%;
        height: 50vh;
    }
  }
}
.empty-container {
  width: 80%;
  height: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 1px 6px #cad6e280;
  color: #cad6e2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  .img {
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.loading {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--primary-color);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>