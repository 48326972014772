<template>
  <div class="search-wrapper">
    <div class="search-bar">
      <input type="text" placeholder="Search user..." v-model="searchKey" />
    </div>
    <div class="results" :class="{ empty: !results.length }">
      <span v-if="!results.length" class="nothing"
        >Search for a user above</span
      >
      <template v-else>
        <div
          class="user"
          v-for="(user, index) in results"
          :key="index"
          @click="handleSelected(user)"
        >
          <div v-if="user.avatar" class="img">
            <img :src="imgURL + user.avatar" alt="" />
          </div>
          <div v-else class="no-img">
            <span>{{ user.name.charAt(0) }}</span>
          </div>
          <div class="name">{{ user.name }}</div>
        </div>
      </template>
    </div>
    <div class="to-create" v-if="selected.length">
      <div class="selected">
        <div class="user" v-for="(user, index) in selected" :key="index">
          <template v-if="index <= 3">
            <div
              v-if="user.avatar"
              class="img"
              :title="user.name"
              @click="removeUser(index)"
            >
              <img :src="imgURL + user.avatar" alt="" />
            </div>
            <div
              v-else
              class="no-img"
              :title="user.name"
              @click="removeUser(index)"
            >
              <span>{{ user.name.charAt(0) }}</span>
            </div>
          </template>
          <template v-if="index === 4">
            <div class="more">+</div>
          </template>
        </div>
      </div>
      <div class="send" @click="handleCreate">create chat</div>
    </div>
  </div>
  <popup
    v-if="showExtras"
    @close="showExtras = false"
    @data="handleCreateGroup"
  />
</template>

<script>
import { mapActions } from "vuex";
import { SEARCH_CHAT_USERS_ACTION } from "@/store/storeconstants";
import Popup from "./GroupPopup.vue";
export default {
  data() {
    return {
      results: [],
      searchKey: "",
      organization_details: JSON.parse(localStorage.getItem("organization")),
      imgURL: process.env.VUE_APP_DO_SPACES,
      selected: [],
      showExtras: false,
    };
  },

  watch: {
    searchKey(val) {
      if (val.length) {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.searchChatUsers();
        }, 1000);
      } else {
        this.searchChatUsers();
      }
    },
  },

  components: {
    Popup,
  },

  emits: ['createChat'],

  methods: {
    ...mapActions("chat", {
      fetchChatUsers: SEARCH_CHAT_USERS_ACTION,
    }),
    async searchChatUsers() {
      if (this.searchKey.length > 0) {
        let params = {
          org_id: this.organization_details.id,
          search_data: this.searchKey,
        };
        await this.fetchChatUsers(params)
          .then((data) => {
            if (data.length) {
              this.results = data.filter((el) => {
                return !this.selected.find((bel) => {
                  return el.userId === bel.userId;
                });
              });
            } else {
              this.results = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.results = [];
      }
    },

    handleSelected(user) {
      if (this.selected.length < 3) {
        this.selected.push(user);
        this.searchChatUsers();
      } else {
        this.emitter.emit(
          "alert",
          "You can only have 4 users (including you) in a group."
        );
      }
    },

    removeUser(index) {
      this.selected.splice(index, 1);
      this.searchChatUsers();
    },

    handleCreate() {
      if (this.selected.length > 1) {
        this.showExtras = true;
      } else if (this.selected.length <= 1)
        this.$emit("createChat", this.selected);
    },

    handleCreateGroup(data) {
      const toSend = { users: this.selected };
      if (data.image) toSend.groupImage = data.image;
      if (data.name) toSend.groupName = data.name;

      this.$emit("createChat", toSend);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  width: 100%;
  position: absolute;
  top: 100%;
  background: white;
  height: 504px;
  z-index: 1;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .search-bar {
    width: 100%;
    height: 10%;
    input {
      width: 100%;
      padding: 10px;
      border: solid 1px #cad6e280;
      border-radius: 5px;
    }
  }
  .results {
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #f2f5f8;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      transition: 0.2s;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e6e8eb;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
    }
    &.empty {
      justify-content: center;
    }
    .nothing {
      color: #cad6e2;
    }
    .user {
      width: 100%;
      display: flex;
      gap: 5px;
      align-items: center;
      border-bottom: solid 1px #cad6e280;
      user-select: none;
      cursor: pointer;
      padding: 10px;
      transition: 0.2s;
      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        background: var(--primary-color);
        color: white;

        .no-img {
          background: white;
          color: var(--primary-color);
        }
      }
      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .no-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: var(--primary-color);
        color: white;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        text-transform: uppercase;
      }
    }
  }
  .to-create {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected {
      width: 60%;
      display: flex;
      overflow: hidden;
      .user {
        margin-left: -15px;
        user-select: none;
        &:first-of-type {
          margin-left: 0;
        }
        .img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .no-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          background: var(--primary-color);
          color: white;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s;
          text-transform: uppercase;
        }
        .more {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          background: #cad6e2;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
        }
      }
    }

    .send {
      width: 40%;
      background: #0072FFd5;
      color: white;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-weight: 1.5rem;
      user-select: none;
      cursor: pointer;
      text-transform: capitalize;
      border-radius: 5px;
      transition: 0.2s;
      &:hover {
        background: var(--primary-color);
      }
    }
  }
}
</style>