<template>
  <header>
    <div class="left">
      <template v-if="chat_item">
        <figure>
          <template v-if="chat_item.imageBase64">
            <img
              :src="'data:image/png;base64, ' + chat_item.imageBase64"
              alt="avatar"
            />
          </template>
          <template v-else-if="otherUsers(chat_item)[0].userInfo.avatar">
            <img
              :src="imgURL + otherUsers(chat_item)[0].userInfo.avatar"
              alt="avatar"
            />
          </template>
          <template v-else>
            <span class="user-char" v-if="chat_item.name">
              {{chat_item.name.charAt(0)}}
            </span>
            <span class="user-char" v-else-if="otherUsers(chat_item)[0].userInfo.name">
              {{otherUsers(chat_item)[0].userInfo.name.charAt(0)}}
            </span>
          </template>
          
        </figure>
        <span class="info">
          <span class="title">{{
            chat_item.name || otherUsers(chat_item)[0].userInfo.name
          }}</span>
          <template v-if="chat_item.users.length > 2">
            <div class="participants">
              <span
                class="participant"
                v-for="user in otherUsers(chat_item)"
                :key="user.id"
                >{{ user.userInfo.name }}</span
              >
            </div>
          </template>
        </span>
      </template>
      <template v-else>
        <span>loading</span>
      </template>
    </div>
    <div class="right">
      <span v-if="chatLoaded" @click="startCall"
        ><img src="/assets/images/phone-icon.png" alt=""
      /></span>
      <span class="options" @click="edit = !edit">
        <img src="/assets/images/black_dots.svg" alt="" />
      </span>
    </div>
  </header>
  <div class="wrapper">
    <template v-if="loaded">
      <div
        class="video-wrapper"
        :class="{ 'w-video': session && !getDisabled }"
      >
        <div class="innerVideo" id="videochat"></div>
      </div>
      <div class="whole" :class="{ 'w-video': session && !getDisabled }">
        <div class="chat-wrapper" ref="wrapper" @scroll="handleScroll">
          <template v-if="chatLoaded">
            <template
              v-for="(chat, chat_index) in chatDetails.chatDetails"
              :key="chat_index"
            >
              <Messages :chat="chat" @joinCall="startCall"/>
            </template>
            <template
              v-for="(chat, chat_index) in newMessages"
              :key="chat_index"
            >
              <Messages :chat="chat" @joinCall="startCall"/>
            </template>
          </template>
          <span class="isTyping" v-if="userIsTyping">
            <img src="/assets/images/typing1.gif" />
          </span>
        </div>
        <template v-if="chatLoaded">
          <Chat :roomId="chatDetails.room" />
        </template>
        <template v-else>
          <div class="loading">
            <span class="loader"></span>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </template>
    <transition name="slide">
      <chat-extras
        v-if="edit"
        :info="chat_item"
        :ticket="false"
        :room="chatDetails.room"
        @searchkey="handleSearch"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  GET_CHATS_LIST,
  UPLOAD_CHAT_ATTACHMENT,
} from "@/store/storeconstants";
import { getCurrentInstance } from "vue";
import Chat from "@/components/Chat/Chat.vue";
import Messages from "./Messages.vue";
import chatExtras from "@/components/Ui/Chat/ChatExtras.vue";
export default {
  props: {
    chat_item: {
      required: false,
      type: Object,
    },
    mode: {
      required: false,
      type: String,
      defatult: () => "assigned",
    },
    loaded: {
      required: false,
      type: Boolean,
    },
    toRead: {
      required: false,
      type: Number,
    },
  },
  emits: ["reads"],
  data() {
    return {
      internalInstance: getCurrentInstance(),
      currentChatPage: 1,
      chatLoaded: false,
      chatDetails: {},
      newMessages: [],
      currentScroll: 0,
      showVid: false,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      session: false,
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      userIsTyping: false,
      unreads: 0,
    };
  },

  components: {
    Chat,
    Messages,
    //optionsModal,
    chatExtras,
  },

  watch: {
    getCallId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        }
        if (val && val === this.chatDetails.room) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
    chat_item: {
      immediate: true,
      handler(val) {
        this.unreads = val.unreadChatCount;
        this.newMessages = [];
        this.currentScroll = 0;
        this.chatLoaded = false;
        this.currentChatPage = 1;
        this.totalChats = 1;
        this.edit = false;
        this.getChatData();
      },
    },

    currentChatPage() {
      this.getChatData();
    },

    chatDetails: {
      deep: true,
      handler(val) {
        if (this.unreads > 0) this.readMessage();
        if (!this.getCallId) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        } else if (this.getCallId && val.room === this.getCallId) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      getCallStatus: "call/getCallStatus",
      getCallId: "call/getCallId",
      getDisabled: "call/getDisabled",
    }),
  },

  methods: {
    ...mapActions("chat", {
      updateChatFile: UPLOAD_CHAT_ATTACHMENT,
      fetchChatConversation: GET_CHATS_LIST,
    }),

    ...mapMutations({
      setDisabled: "call/setDisabled",
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
    }),

    closeOptions() {
      this.edit = false;
    },

    otherUsers(item) {
      return item.users.filter((el) => el.userId !== this.user.id);
    },

    async getChatData(key) {
      if (this.chat_item) {
        let chatParams = {
          page_id: this.currentChatPage,
          room_id: this.chat_item._id,
        };

        if (key) chatParams.search_data = key;
        await this.fetchChatConversation(chatParams)
          .then(async (response) => {
            this.totalChats = Math.ceil(response.data.totalChatCount / 10);
            this.chatLoaded = true;
            const wrapper = this.$refs.wrapper;

            if (response && this.currentChatPage === 1) {
              this.chatDetails = response.data;
              this.chatDetails.chatDetails.reverse();
              setTimeout(() => {
                wrapper.scrollTop = wrapper.scrollHeight;
              }, 200);
            } else if (response && this.currentChatPage !== 1) {
              let newChats = response.data.chatDetails.reverse();
              this.chatDetails.chatDetails.unshift(...newChats);
              setTimeout(() => {
                wrapper.scrollTop = wrapper.scrollHeight - this.currentScroll;
              }, 1);
            }
          })
          .catch((error) => {
            this.chatLoaded = false;
            console.log(error);
          });
      }
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0 && this.currentChatPage < this.totalChats) {
        this.currentScroll = e.target.scrollHeight;
        this.currentChatPage += 1;
      }
    },

    startCall() {
      let userToSend = this.user;
      userToSend.type = "single";
      const params = {
        roomId: this.chatDetails.room,
        with: userToSend,
      };
      this.setCallStatus(true);
      this.setCallId(this.chatDetails.room);
      this.emitter.emit("request-to-join-call", params);
      this.emitter.emit("video-section-prepared", params);
    },

    readMessage() {
      let unreadMsg = [];
      this.chatDetails.chatDetails.forEach((element) => {
        const receivers = element.receivers
          .filter((el) => !el.isRead)
          .map((el) => el.userId);
        if (receivers.length) {
          if (receivers.includes(this.user.id)) unreadMsg.push(element._id);
        }
      });
      const data = {
        room: this.chatDetails.room,
        chatIds: unreadMsg,
      };
      this.emitter.emit("read-message", data);
      this.$emit("reads", unreadMsg.length);
      this.unreads -= unreadMsg.length;
    },

    handleSearch(data) {
      this.chatDetails = {};
      this.newMessages = [];
      this.getChatData(data);
    },
  },

  mounted() {
    this.getChatData();
    this.emitter.on("new-message", (data) => {
      if (data.room && data.room._id === this.chatDetails.room) {
        let newArr = [
          {
            id: data._id,
            attachmentUrls: data.attachmentUrls,
            createdAt: data.createdAt,
            media: data.media,
            receivers: data.receivers,
            room: data.room,
            sentBy: data.sentBy,
            status: data.status,
            text: data.text,
            type: data.type,
          },
        ];
        this.newMessages.push(...newArr);
        if (data.type === "rejectCall") this.emitter.emit("destroy-call");

        setTimeout(() => {
          const wrapper = this.$refs.wrapper;
          if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
        }, 200);
      }
    });

    this.emitter.on("chats-deleted", (data) => {
      if (data.room === this.chatDetails.room) {
        this.newMessages = [];
        this.chatDetails.chatDetails = [];
        this.chatDetails.totalChatCount = 0;
      }
    });

    this.emitter.on("user-typing", (data) => {
      if (
        data.room === this.chatDetails.room &&
        data.userDetails.userId !== this.user.id
      ) {
        this.userIsTyping = true;
        const wrapper = this.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

        clearTimeout(timer);
        const timer = setTimeout(() => {
          this.userIsTyping = false;
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}

header {
  height: 12%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 1px 6px #cad6e280;
  border-bottom: solid 1px #f2f5f8;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    gap: 5px;
    align-items: center;
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .user-char {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        background: var(--primary-color);
        color: white;
        border-radius: 50%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1.2rem;
        font-weight: 500;
        color: black;
      }
      span {
        font-size: 0.8rem;
        color: #cad6e2;
        .name {
          color: black;
        }
      }

      .participants {
        display: flex;
        gap: 5px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      user-select: none;
      cursor: pointer;
    }

    .options {
      position: relative;
      padding: 10px;
    }

    .close-tckt {
      padding: 5px 20px;
      color: #ed0423;
      border-radius: 30px;
      border: solid 1px #ed0423;
      transition: 0.2s;

      &:hover {
        border: solid 1px transparent;
        background: #ed0423;
        color: white;
      }
    }
  }
}
.wrapper {
  padding: 20px;
  position: relative;
  height: 88%;
  display: flex;
  .video-wrapper {
    width: 0px;
    &.w-video {
      width: 50%;
      background: black;
      display: flex;
      align-items: center;
    }
    .innerVideo {
      width: 100%;
      height: 100%;
      max-height: 640px;
    }
  }
  .whole {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.w-video {
      width: 50%;
      padding: 0 0 0 20px;
      .chat-wrapper {
        .details-wrapper {
          width: 100%;
        }
      }
    }
    .chat-wrapper {
      height: 95%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px 20px 0;
      scroll-behavior: smooth;
      transition: 0.2s;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #f2f5f8;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        transition: 0.2s;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
      }
    }
  }

  .chat-bar {
    height: 5%;
  }
}

.test {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}

.isTyping {
  height: 30px;
  img {
    height: 100%;
  }
}

.loading {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    width: 0px;
    overflow: hidden;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 25%;
    overflow: hidden;
  }
}
</style>