<template>
  <div class="contentTitle">
    <h3>Chat</h3>
  </div>
  <section class="columns">
    <chats
      :items="entries"
      :mode="mode"
      :loaded="loaded"
      @reloadChats="getSingleUsersData()"
      @mode="handleMode"
    />
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Chats from "@/components/Chat/Chats.vue";
import {
  GROUP_CONVERSATION_LIST_ACTION,
  SINGLE_CONVERSATION_USERS_LIST_ACTION,
} from "@/store/storeconstants";

export default {
  title() {
    return `Remote Assistance | Chat`;
  },
  data() {
    return {
      create: false,
      entries: [],
      total: 0,
      imgURL: process.env.VUE_APP_DO_SPACES,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      isActive: 1,
      mode: "single",
      loaded: false,
      currentChatPage: 1,
      organization_details: JSON.parse(localStorage.getItem("organization")),
    };
  },

  components: {
    Chats,
  },

  methods: {
    ...mapActions("chat", {
      singleConversationUsers: SINGLE_CONVERSATION_USERS_LIST_ACTION,
      groupConversations: GROUP_CONVERSATION_LIST_ACTION,
    }),

    async getSingleUsersData() {
      this.loaded = false;
      let params = {
        page_id: this.currentChatPage,
        org_id: this.organization_details.id,
      };
      await this.singleConversationUsers(params)
        .then((data) => {
          this.entries = data.roomDetails;
        })
        .finally(() => (this.loaded = true))
        .catch((error) => {
          console.log(error);
        });
    },

    async getGroupConversation() {
      this.loaded = false;
      let params = {
        page_id: this.currentChatPage,
        org_id: this.organization_details.id,
      };
      await this.groupConversations(params)
        .then((data) => {
            console.log(data)
          this.entries = data.roomDetails;
        })
        .finally(() => (this.loaded = true))
        .catch((error) => {
          console.log(error);
        });
    },

    handleMode(val) {
      switch (val) {
        case "single":
          this.getSingleUsersData();
          break;
        case "group":
          this.getGroupConversation();
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.getSingleUsersData();
  },
};
</script>

<style lang="scss" scoped>
.contentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navbar-nav {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .nav-item {
      display: flex;
      gap: 20px;
      .nav-link {
        user-select: none;
        cursor: pointer;
        &.active {
          border-bottom: solid 1px black;
        }
      }
    }
  }
  .search-filters {
    display: flex;
    gap: 20px;
    align-items: center;
    .searchPan {
      position: relative;
      display: flex;
      align-items: center;
      .searchIcon {
        position: absolute;
        left: 10px;
        color: #cad6e2;
      }
      .form-control {
        padding: 0 30px;
        &::placeholder {
          color: #cad6e2;
        }
      }
    }
  }
}

.columns {
  display: flex;
  justify-content: space-between;
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>